import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { useApollo } from "@neonjungle/headless";
import { ApolloProvider } from "@apollo/client";
import Script from "next/script";

export default function App({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);
  const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
  return (
    <ApolloProvider client={apolloClient}>
      <Component {...pageProps} />
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script id="google-analytics">
        {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());

           gtag('config', '${GA_TRACKING_ID}');
         `}
      </Script>
    </ApolloProvider>
  );
}
